import React, { useEffect, useRef, useState } from 'react';
import { debounce } from "throttle-debounce"
import { MentionsInput, Mention } from "react-mentions";
import PropTypes from "prop-types";

import UserTileGeneral from "components/UserTileGeneral";
import { convertToLocaleDateTimeString } from "sharedUtils/timeUtils"
import PostBanner from "components/PostBanner";
import FormTextArea from "components/FormTextArea";

import { getRequest } from "sharedUtils/httpUtils";


const PreviewCard = (props) => {
    const { post } = props;
    const [mediaIndex, setMediaIndex] = useState(-1);
    const [captionInput, setCaptionInput] = useState("");
    const maxLength = 256;  // Set max caption length

    const debounceFunc = React.useCallback(debounce(300, (query, callback) =>
        fetchUsers(query, callback)), []);

    const handleMounted = async () => {
        if (post?.media?.length > 0) {
            setMediaIndex(0);
            setCaptionInput(props.post.content);
        }
    }

    useEffect(() => {
        handleMounted();
    }, []);

    let content = post?.content ?? post?.title;

    function getProperties() {
        let properties = {};

        let values = props.post.media
            .map((media, i) => {
                if (media?.children?.length > 0) {
                    return { parentId: i, value: media.children[0].thumbnailLink }
                } else {
                    return { parentId: null, value: media.imageLink || media.videoLink }
                }
            });

        properties.$values = values;
        return properties;
    }

    async function fetchUsers(query, callback) {
        if (!query || query.length < 1) {
            return;
        }

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;
        getRequest(url)
            .then(res => {
                return res.map(user => ({ display: user.userName, id: user.id, name: user.name }))
            })
            .then(callback);
    }

    let webLayouts = ["md", "lg", "xl"];

    let postImages =
        post.platformName === "youtube" || post.platformName === "tiktok" ?
            props.post.media
                .map((media, i) => {
                    return {
                        url: post.link,
                        role: 3,
                        id: i,
                        height: media.height,
                        width: media.width
                    }
                }) :
            props.post.media
                .map((media, i) => {
                    return {
                        url: !!media.imageLink ? media.imageLink : media.videoLink,
                        role: !!media.imageLink ? 0 : 1,
                        id: i
                    }
                });

    // Updated onChange handler to enforce character limit
    const handleCaptionChange = (event, newValue, newPlainTextValue) => {
        if (!props.blog && newValue?.length > maxLength) {
            return;
        }
        setCaptionInput(newValue);
        props.handleContentInput(newValue);
    };

    return (
        <section className={`container my-2 ${props.deviceSize === "sm" ? "pl-0 pr-0" : ""}`}>

            {props.deviceSize === "sm" ?
                (<div className="row pl-0 mx-0 pt-0 mt-0">
                    <div className="col-8 mt-0 pl-2">
                        <UserTileGeneral
                            user={props.loggedInUser}
                            subtitle={" "}
                            showCheckMark={false} />
                    </div>
                </div>) : null}

            <div className="row mx-0 mt-2">

                <div className={`col-md-8 ${props.deviceSize === "sm" ? "mt-0 px-0" : "pr-2 mt-2 ml-0 px-0"}`}>

                    <PostBanner
                        images={postImages}
                        customClass="post-page-banner-modal"
                        properties={getProperties()}
                    />

                </div>

                {
                    webLayouts.includes(props.deviceSize) ? (
                        <div className="col-md-4 mt-2 px-0">

                            <UserTileGeneral
                                user={props.loggedInUser}
                                subtitle={" "}
                                showCheckMark={false} />
                            {
                                !!(props.blog) ?
                                    <>
                                        <label className="control-label mt-2" style={{ fontFamily: "Archivo" }}>
                                            <strong>Title</strong>
                                        </label>
                                        <p style={{ marginLeft: "10px" }}>{post.title}</p>
                                    </>
                                    :
                                    <></>
                            }


                            <label className="control-label" style={{ fontFamily: "Archivo" }}>
                                <strong>{!!(props.blog) ? "Description" : "Caption"}</strong>
                            </label>

                            <MentionsInput
                                className="comments-textarea"
                                value={captionInput}
                                onChange={handleCaptionChange}

                                style={{ height: "auto" }}
                                allowSuggestionsAboveCursor={true}
                            >
                                <Mention
                                    trigger="@"
                                    data={debounceFunc}
                                    displayTransform={(id, display) => `@${display}`}
                                    markup="#!^(__id__)%~@(((__display__)))"
                                    renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                                        return <span><strong>@{entry.display}</strong><div><small>{entry.name}</small></div></span>
                                    }}
                                >
                                </Mention>
                            </MentionsInput>
                            <p className="position-relative" style={{ display: props.blog ? "none" : "", textAlign: "right", fontSize: "12px", color: "#717171" }}>{256 - (captionInput?.length ?? 0)} characters remaining</p>

                        </div>

                    ) : null
                }

                {
                    props.deviceSize === "sm" ? (
                        <>
                            {
                                !!(props.blog) ?
                                    <>
                                        <h6 className="mt-2 mx-2" style={{ marginLeft: "10px" }}>{post.title}</h6>
                                    </>
                                    :
                                    <></>
                            }
                            <div className={`container container-top-gap mt-1 px-2`}>
                                <p className="post-page-text mt-1 mb-0">
                                    <span>{content}</span>
                                </p>
                            </div>
                        </>
                    ) : null
                }

            </div>
        </section>
    );
}

PreviewCard.propTypes = {
    userName: PropTypes.string,
    profilePicture: PropTypes.node,
    post: PropTypes.object,
    loggedInUser: PropTypes.object,
    handleContentInput: PropTypes.func,
    captionValue: PropTypes.string
}

export default PreviewCard;
