import React from "react";
import AccountBanner from "../../components/AccountBanner"
import RedirectFailureAlarms from "./RedirectFailureAlarms";
import DailySpikeAlarms from "./DailySpikeAlarms";
import SalesDailySpikeAlarms from "./SalesDailySpikeAlarms"
import WeeklyLinkSpikeAlarms from "./WeeklyLinkSpikeAlarms"
import LinksLowEngagementAlarms from "./LinksLowEngagementAlarms"
import ClicksToSalesHighLowSpikesAlarms from "./ClicksToSalesHighLowSpikesAlarms";

class Alarms extends React.Component {

    state = {
        selectedCase: 1,
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Alarms', text: 'Alarms' }];
    redirectFailuresBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Redirect Failures', active: true },
    ];

    dailySpikesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Clicks Daily Spikes', active: true }
    ];

    salesDailySpikesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Sales Daily Spikes', active: true }
    ];

    weeklyLinkSpikesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Weekly Link Spikes', active: true }
    ];

    LinksLowEngagementBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Links Low Engagement', active: true }
    ];

    ClicksToSalesHighLowBreadcrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Alarms', text: 'Sales To Clicks', active: true }
    ];


    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Redirect Failures",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Clicks Daily Spikes",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Sales Daily Spikes",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
            {
                name: "Weekly Link Spikes",
                case: 4,
                action: () => this.setState({ selectedCase: 4 })
            },
            {
                name: "Links Low Engagement",
                case: 5,
                action: () => this.setState({ selectedCase: 5 })
            },
            {
                name: "Sales To Clicks",
                case: 6,
                action: () => this.setState({ selectedCase: 6 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    getBreadCrumbList = () => {
        switch (this.state.selectedCase) {
            case 1: return this.redirectFailuresBreadCrumbList;
            case 2: return this.dailySpikesBreadCrumbList;
            case 3: return this.salesDailySpikesBreadCrumbList;
            case 4: return this.weeklyLinkSpikesBreadCrumbList;
            case 5: return this.LinksLowEngagementBreadCrumbList;
            case 6: return this.ClicksToSalesHighLowBreadcrumbList;
            default: return this.breadCrumbList;
        }
    };

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.getBreadCrumbList()}>
                        <h1 className="mb-4">Alarms</h1>
                        <p className="mb-0">Management of system alarms and alerts</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex" >
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <RedirectFailureAlarms
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <DailySpikeAlarms
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <SalesDailySpikeAlarms
                        display={this.state.selectedCase === 3}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <WeeklyLinkSpikeAlarms
                        display={this.state.selectedCase === 4}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <LinksLowEngagementAlarms
                        display={this.state.selectedCase === 5}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <ClicksToSalesHighLowSpikesAlarms
                        display={this.state.selectedCase === 6}
                        loggedInUser={this.props.loggedInUser}
                    />
                </section>
            </>
        )
    }
}

export default Alarms;