import React from "react";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import MUIDataTable from "mui-datatables";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import ActionButton from "components/ActionButton";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import toast, { Toaster } from 'react-hot-toast';

const { DateTime } = require("luxon");

class RedirectFailureAlarms extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            startDate: DateTime.utc().minus({ weeks: 1 }).startOf('day'),
            endDate: DateTime.utc().endOf('day'),
            minDate: "1753-01-01",
            dateError: false,
            maxDate: "9999-12-31",
            showResolved: false,
            alarms: [],
            partners: [],
            value: [],
            alarmsLoading: false,
            selected: [],
            options: [],
            skip: 0,
            take: 50,
            totalCount: 0,
            loading: false,
            RedirectFailuresTable: {
                title: "Redirect Failures - Creator links with a high percentage of failures",                
                options: {
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                        <div className="d-flex flex-row align-items-center mx-1">
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmResolved(selectedRows, displayData)}>
                                        Resolve Selected URLs
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                    selectableRows: "multiple",
                    rowsPerPage: 100,
                    sortOrder: {
                        name: 'userId',
                        direction: 'asc'
                    },
                },
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                    }
                })
            },
        };
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Analytics/Analytics', text: 'Analytics', active: true }];

    componentDidMount = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
        this.getAlarms();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const { startDate, endDate, minDate, maxDate } = this.state;

        // Only run validation if dates changed
        if (prevState.startDate !== startDate || prevState.endDate !== endDate) {

            // Convert minDate/maxDate (which are strings) to Luxon DateTime
            const minDateObj = DateTime.fromISO(minDate);  // "1753-01-01"
            const maxDateObj = DateTime.fromISO(maxDate);  // "9999-12-31"

            // Check:
            // 1) Both dates are valid
            // 2) Both dates fall within [minDate, maxDate]
            // 3) startDate < endDate
            if (
                startDate?.isValid && endDate?.isValid &&
                startDate >= minDateObj && startDate <= maxDateObj &&
                endDate >= minDateObj && endDate <= maxDateObj &&
                startDate < endDate
            ) {
                this.setState({ dateError: false });
                await this.getAlarms();
            } else {
                this.setState({ dateError: true });
            }
        }
    };


    confirmResolved = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("Are you sure you want to RESOLVE these items");
        if (confirmDelete) {
            this.handleResolved(selectedRows, displayData);
        }
    }

    handleResolved = async (selectedRows, displayData) => {
        //this.setState({ loading: true });

        const selectedRowsData = selectedRows.data;
        const alarmIds = selectedRowsData.map(row => displayData[row.index].data[0]);
        const deleteUrl = "Products/PostAlarmHistoryResolved";

        const payload = {
            alarmIds
        }

        setTimeout(() => {
            this.tableRef.current.setState({ selectedRows: { data: [] } });
        }, 3000);

        const success = await postRequest(deleteUrl, payload);

        if (success) {
            await this.getAlarms();
            this.tableRef.current.setState({ selectedRows: { data: [] } }); // Deselects the table rows using this.tableRef created with React.createRef()

            this.setState({ deleteError: false });
        } else {
            this.setState({ deleteError: true });
        }

        this.setState({ loading: false });
    }


    handleStartDateChange = (event) => {
        const startDateRaw = event.target.value;
        if (startDateRaw.length === 0) return;

        const startDate = DateTime.fromISO(startDateRaw).toUTC().startOf('day');
        if (startDate.isValid) {
            this.setState({ startDate });
        } else {
            console.error("Invalid start date entered:", startDateRaw);
        }
    };

    handleEndDateChange = (event) => {
        const endDateRaw = event.target.value;
        if (endDateRaw.length === 0) return;

        const endDate = DateTime.fromISO(endDateRaw).toUTC().endOf('day');
        if (endDate.isValid) {
            this.setState({ endDate });
        } else {
            console.error("Invalid end date entered:", endDateRaw);
        }
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().endOf('day') });
    };

    getAlarms = async () => {
        let url = `Products/GetAlarmRedirectFailures`;
        const { startDate, endDate, showResolved } = this.state;
        const offset = DateTime.now().offset;

        if (startDate) url += `?startDate=${startDate.toSQL({ includeOffset: false })}`;
        if (endDate) url += `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        url += `&showAll=${showResolved}`;
        url += `&offset=${offset}`;

        this.setState({ alarmsLoading: true });

        try {
            const alarms = await getRequest(url);
            this.setState({ alarms, alarmsLoading: false });
        } catch (error) {
            console.error("Error fetching alarms:", error);
            this.setState({ alarms: [], alarmsLoading: false });
        }
    };


    handleShowResolvedChange = (event) => {
        const showResolved = event?.target?.checked ?? false; // Safely get the checked value or default to false

        this.setState({ showResolved, alarms: [] }, () => this.getAlarms());
    };


    setSelected = (selected) => {
        if (selected != null) {
            this.setState({ selected });
        }
    };

    fetchMerchants = async (query) => {
        this.setState({ loading: true });
        const options = await getRequest(`/api/Merchant/MerchantSearchByMerchantName?merchantName=${query}`);
        this.setState({ options, loading: false });
    };

    render() {
        const columns = [
            {
                name: "alarmId",
                label: "Alarm ID",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "merchantId",
                label: "Merchant ID",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "shareCode",
                label: "ShareCode",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "creatorUserId",
                label: "Creator UserId",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "merchantLinkId",
                label: "MerchantLink ID",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "userMerchantProductId",
                label: "UserMerchantProduct ID",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "alarmDate",
                label: "Date",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                    },
                },
            },
            {
                name: "resolvedDate",
                label: "Resolved Date",
                options: {
                    filter: true,
                    sort: true,
                    display: this.state.showResolved, // Reflects the current state
                    customBodyRender: (value) => {
                        if (!!value) {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                        return "-";
                    },
                },
            },
            {
                name: "creatorUserName",
                label: "Creator",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "creatorEmail",
                label: "Creator Email",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "description",
                label: "Description",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = tableMeta.rowData;
                        const merchantId = rowData[1];
                        const creatorUserId = rowData[3];
                        const merchantLinkId = rowData[4];
                        const shareCode = rowData[2];
                        const destinationUrl = rowData[8];

                        const schemeAndHost = `${window.location.protocol}//${window.location.host}`;

                        let redirectUrl;

                        if (merchantLinkId) {
                            redirectUrl = `${schemeAndHost}/mlink/${merchantId}?userId=${creatorUserId}&redirectUrl=${encodeURIComponent(destinationUrl)}`;
                        } else {
                            redirectUrl = `${schemeAndHost}/prdlink/${shareCode}`;
                        }

                        return value ? (
                            <a
                                href={redirectUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="brand-primary-color"
                            >
                                {value}
                            </a>
                        ) : "-";
                    },
                },
            },
            {
                name: "destinationUrl",
                label: "Destination URL",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        return value ? (
                            <a
                                href={value}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="brand-primary-color"
                            >
                                {value}
                            </a>
                        ) : "-";
                    },
                },
            },
            {
                name: "brand",
                label: "Brand",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "merchant",
                label: "Merchant",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "sevenDayClicks",
                label: "7 Day Clicks",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "sevenDayFailures",
                label: "7 Day Failures",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "sevenDayFailureRate",
                label: "7 Day Failure Rate",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        return value && value > 0 ? `${(value.toFixed(2) * 100)}%` : "0%";
                    },
                },
            },
            {
                name: "clicks",
                label: "Clicks",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "failures",
                label: "Failures",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: "failureRate",
                label: "Failure Rate",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value) => {
                        return value && value > 0 ? `${(value.toFixed(2) * 100)}%` : "0%";
                    },
                },
            },
        ];

        return (
            <div style={{ display: this.props.display ? "" : "none" }}>
                <Toaster />

                <section className="gray-bg mb-0 pt-2" style={{ display: "none" }}>
                    <div className="container pt-0 pb-0">
                        <div className="search-interest py-1 mx-auto">
                            <div className="search-block d-md-flex justify-content-between">
                                <div>
                                    <AsyncTypeahead
                                        id="merchantSearch"
                                        labelKey="name"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchMerchants}
                                        onChange={e => this.setSelected(e)}
                                        isLoading={this.state.loading}
                                        options={this.state.options}
                                        placeholder="Search for merchants..." />
                                </div>
                                <ActionButton className={`btn btn-primary`}
                                    style={{ height: "38px" }}
                                    onClick={this.handleGetReport}
                                >
                                    Get Report
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </section>               

                <section className="gray-bg mb-0 pt-2">
                    <div className="pt-0 pb-0 px-5">
                        <div
                            className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-4"
                                } mx-auto`}
                        >
                            <div
                                className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""
                                    } justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`}
                                style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}
                            >
                                <p className="ml-5 pt-3 mb-0" style={{ color: "red", display: this.state.dateError ? "" : "none" }}>Dates must be valid dates and Start Date must be before the End Date </p>

                                <div className="d-flex flex-column">
                                    <label className="mb-0" style={{ cursor: "pointer" }}>
                                        <input
                                            type="checkbox"
                                            checked={this.state.showResolved}
                                            onChange={this.handleShowResolvedChange}
                                            style={{ marginRight: "8px" }}
                                        />
                                        Resolved Alarms
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg mb-0 pt-0" style={{ display: this.state.showResolved ? "" : "none" }}>
                    {
                        !["sm"].includes(this.props.deviceSize) ?
                            <></>
                            :
                            <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                            </div>
                    }
                    <div className="pt-0 pb-0 px-5">

                        <div className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-0"} mx-auto`}>

                            <div className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`} style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}>

                                <div className="d-flex flex-column">
                                    <label className="mb-0">Start Date:</label>
                                    <TextField
                                        id="start-date"
                                        type="date"
                                        value={this.state.startDate.toISODate()}
                                        onChange={this.handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }} />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="mb-0">End Date:</label>
                                    <TextField
                                        id="end-date"
                                        type="date"
                                        value={this.state.endDate.toISODate()}
                                        onChange={this.handleEndDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                ["sm"].includes(this.props.deviceSize) ?
                                    <></>
                                    :
                                    <div className="d-flex" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                        <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                        <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                        <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                    </div>
                            }

                        </div>
                    </div>

                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.RedirectFailuresTable.getTheme()}>
                                    <MUIDataTable
                                        title={
                                            <div style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                <p style={{ fontSize: '22px', marginTop: "20px" }}>Redirect Failures</p>
                                                <p style={{ fontSize: '14px', marginBottom: "0" }}>Creator links with a high percentage of failures</p>
                                            </div>
                                        }
                                        data={this.state.alarms}
                                        columns={columns}
                                        options={this.state.RedirectFailuresTable.options}
                                        ref={this.tableRef}
                                    />

                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default RedirectFailureAlarms;
