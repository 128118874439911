import React from "react";
import { MentionsInput, Mention } from "react-mentions";

/**
 * 
 * @param {{
 *   className: string,
 *   value: any,
 *   onChange: Function,
 *   style?: object, 
 *   allowSuggestionsAboveCursor?: boolean,
 *   data: Function,
 *   placeholder?: string,
 *   maxLength?: number
 * }} props
 */
const MentionsInputTextArea = (props) => {
    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
        // If a maxLength is provided and the new plain text exceeds it, do not update.
        if (props.maxLength && newValue.length > props.maxLength) {
            // Optionally, you could show a warning message or simply ignore extra input.
            return;
        }
        // Otherwise, propagate the change upward.
        props.onChange(event, newValue, newPlainTextValue, mentions);
    };

    return (
        <MentionsInput
            className={props.className}
            value={props.value}
            onChange={handleChange}
            style={props.style || { height: "auto" }}
            allowSuggestionsAboveCursor={props.allowSuggestionsAboveCursor || true}
            placeholder={props.placeholder || ""}
        >
            <Mention
                trigger="@"
                data={props.data}
                displayTransform={(id, display) => `@${display}`}
                markup="#!^(__id__)%~@(((__display__)))"
                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                    return (
                        <span>
                            <strong>@{entry.display}</strong>
                            <div>
                                <small>{entry.name}</small>
                            </div>
                        </span>
                    );
                }}
            />
        </MentionsInput>
    );
};

export default MentionsInputTextArea;
