import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";

import ThreeDotsHorizontalIcon from "../icons/ThreeDotsHorizontalIcon";
import RightArrowIconSmall from "../icons/RightArrowIconSmall";
import ShareBar from "../Share/ShareBar";
import ImageWrapper from "components/ImageWrapper";
import DummyProfilePicture from "components/DummyProfilePicture";
import PostThreeDotMenu from "../../views/Post/PostThreeDotMenu";
import UserTileGeneral from "components/UserTileGeneral";
import BlueTickIconXY from "../icons/BlueTickIconXY";
import PinIcon from "components/icons/PinIcon";
import BookmarkIcon from "components/icons/BookmarkIcon";
import ShareIosIcon from "components/icons/ShareIosIcon";

import { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";

import { trimProductsArray, getProperties, getThumbnail } from "sharedUtils/postFunctions"
import { convertToLocaleDateTimeString } from "../../sharedUtils/timeUtils"
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";
import { getPostShareLink, getBlogShareLink, getLockerShareLink } from "../../sharedUtils/shareLinkPrefixes"

/**
 * @description: A post component for a single column feed
 * @param {{post:object,redirectUrl:string, likePost:Function, savePost:Function, likeCount:Number, likedByUser:boolean, savedByUser:boolean, deletePost:Function}} props
 */
const BlogFeedPost = (props) => {
    const [currentDisplayedMedia, setMedia] = useState(0);
    const [showDotMenu, setShowDotMenu] = useState(false);
    const [productPage, setProductPage] = useState(0);
    const { name, imageUrl, userName } = props.post?.user;
    const { postedOn, content, productItems, properties, shareCode, title } = props.post;
    const { likeCount, likedByUser, savedByUser, commentCount } = props;
    const products = trimProductsArray(productItems.$values).sort(function (a, b) {
        return a.ordinal - b.ordinal;
    });
    const productPageHandlers = useSwipeable({
        onSwipedLeft: () => nextPage(),
        onSwipedRight: () => prevPage(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const mediaPageHandlers = useSwipeable({
        onSwipedLeft: () => nextPicture(),
        onSwipedRight: () => prevPicture(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const mediaItems = getProperties(properties)
        .filter(property => property.parentId == null && (property.role === 0 || property.role === 1 || property.role === 3))
        .map(property => {
            return {
                url: property.value,
                role: property.role,
                id: property.id,
                height: property.height,
                width: property.width
            }
        });

    const collapsedContentStyle = { textAlign: "justify", marginTop: "0em", maxHeight: "3em", textOverflow: "ellipsis", overflow: "hidden" };
    const notOverflowedContentStyle = { textAlign: "justify", marginTop: "0em", marginBottom: "0.5em", maxHeight: "3em", textOverflow: "ellipsis", overflow: "hidden" };
    const expandedContentStyle = { textAlign: "justify", marginTop: "0em" };

    const [contentOverflowed, setContentOverflowed] = useState(false);
    const [contentStyle, setContentStyle] = useState(notOverflowedContentStyle);
    const [collapsed, setCollapsed] = useState(true);
    const [shareLink, setShareLink] = useState("");

    const contentRef = React.createRef();

    useEffect(() => {
        async function getShareLink() {
            let shareLink = await getBlogShareLink(props.post?.shareCode);
            setShareLink(shareLink);
        };
        getShareLink();

        let contentOverflowed = contentRef?.current?.offsetHeight < contentRef?.current?.scrollHeight || contentRef?.current?.offsetWidth < contentRef?.current?.scrollWidth;
        if (contentOverflowed) {
            setContentOverflowed(contentOverflowed);
            setContentStyle(collapsedContentStyle);
        }
    }, []);


    function handleTextMarkup(text) {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal, true);
            } else {
                return retVal;
            }
        });

        return markupHandled;
    }


    let dateObject = new Date(postedOn);

    const toggleDotMenu = () => {
        setShowDotMenu(!showDotMenu);
    }

    function displayTextContent() {
        let formattedContent = handleTextMarkup(content, true);
        if (content !== undefined) {
            return <div className="blog-feed-post-content px-1" ref={contentRef}>
                {formattedContent}
            </div>
        }
    }

    const prevPage = () => {
        if (products.length > 4) {
            let page = productPage - 1;
            if (page < 0) {
                page = Math.floor((products.length - 1) / 4);
            }

            setProductPage(page);
        }
    }

    const nextPage = () => {
        if (products.length > 4) {
            let page = productPage + 1;
            if (page * 4 >= products.length) {
                page = 0;
            }

            setProductPage(page);
        }
    }

    const renderProductCount = () => {
        if (products.length === 1) {
            return "1 product"
        } else if (products.length > 1) {
            return products.length + " products"
        }
        return "";
    }


    let renderBookmarkOverlay = () => {
        return (<span className="feed-page-bookmark-overlay position-absolute"
            style={{}}
            onClick={(e) => {
                if (props.loggedInUser) {
                    e.preventDefault()
                    props.savePost(shareCode);
                } else {
                    props.toggleSignUpModal();
                }

            }} style={{ cursor: "pointer" }}>
            <BookmarkIcon height="16" width="16" fill={props.post.savedByUser === true ? "#e55934" : "none"} />
        </span>);
    }

    let renderShareOverlay = () => {
        return (<span className="feed-page-share-overlay position-absolute"
            onClick={() => {
                props.toggleShareModal(shareLink)
            }} style={{ cursor: "pointer" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    const renderProductPagination = () => {
        if (products && products.length > 4) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <span className="item-count d-flex justify-content-center align-items-center"
                        style={{ top: "-105px" }}
                    >
                        {renderProductCount()}
                    </span>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
        return (
            <span className="item-count d-flex justify-content-center align-items-center"
                style={{ top:"-105px" }}
            >
                {renderProductCount()}
            </span>
        );
    }

    function mapProductImages() {
        if (products && products.length > 0) {
            return products.slice(productPage * 4, productPage * 4 + 4).map((product, i) => {
                let customStyle = { padding: "0px !important" };
                const url = "/prdlink/" + product.shareCode;
                return (
                    <div key={i} className="col-3 px-0">
                        <div className="product-image">
                            <a href={url} target={product.merchant} style={{ cursor: "pointer" }}>
                                <ImageWrapper
                                    src={product.imageUrl}
                                    alt=""
                                    key={i}
                                    className="feed-post-product-image"
                                    size={"Post"}
                                    lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                                />
                            </a>
                        </div>
                    </div>
                )

            })
        }
    }

    function prevPicture() {
        if (currentDisplayedMedia > 0) {
            let newMedia = currentDisplayedMedia;
            setMedia(--newMedia);
        } else {
            setMedia(mediaItems.length - 1);
        }
    }

    function nextPicture() {
        if (currentDisplayedMedia < mediaItems.length - 1) {
            let newMedia = currentDisplayedMedia;
            setMedia(++newMedia);
        } else {
            setMedia(0);
        }
    }

    function toggleModal(isCommenting) {
        props.toggleBlogPageModal(props.post, props.loggedInUser, isCommenting, props.setPost);
    }

    const renderDotMenu = () => {
        if (props.loggedInUser) {
            return (
                <PostThreeDotMenu
                    isOpen={showDotMenu}
                    toggleModal={toggleDotMenu}
                    loggedInUser={props.loggedInUser}
                    post={props.post}
                    deletePost={props.deletePost}
                    toggleMenu={toggleDotMenu}

                />
            );
        }
    }

    const displayPin = () => {
        if (props.showPins) {
            let disablePointer = props.loggedInUser?.id !== props.post.user.id;
            let disablePointerClass = disablePointer ? "disabled-pin-icon" : "";
            if (props.pinnedDate) {
                return (
                    <a href="#" className={disablePointerClass} onClick={(e) => {
                        e.preventDefault();
                        if (props.loggedInUser?.id === props.post.user.id) {
                            props.togglePinned(props.post.shareCode);
                        }

                    }}>
                        <span className="mr-1">
                            <PinIcon isFilled={true} />
                        </span>
                    </a>
                )
            }
            else {
                if (props.loggedInUser?.id === props.post.user.id) {
                    return (
                        <a href="#" className={disablePointerClass} onClick={(e) => {
                            e.preventDefault();
                            props.togglePinned(props.post.shareCode);
                        }}>
                            <span className="mr-1">

                                <PinIcon isFilled={false} />

                            </span>
                        </a>
                    )
                }
            }
        }

    }

    const redirectUrl = getProperties(properties).filter(property => property.role === 6)[0]?.value;


    return (
        <div className="activity-info-block" style={{ borderRadius: "20px" }}>
            {renderDotMenu()}
            <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-2">
                <UserTileGeneral className="py-2 ml-2" user={props.post.user} subtitle={" "} showCheckMark={false} />
                {
                    props.post.status === 0 ? (

                        <span style={{ color: "red" }}>
                            DRAFT
                        </span>

                    ) : null
                }

                <div>
                    {
                        displayPin()
                    }
                    <a href="/" className="setting-icon mr-2" onClick={(e) => {
                        e.preventDefault(); toggleDotMenu()
                    }}>
                        {props.loggedInUser ?
                            <ThreeDotsHorizontalIcon /> : <></>}
                    </a>
                </div>
            </div>

            <div {...mediaPageHandlers} className="feed-post-image-wrapper image-block position-relative">
                {renderBookmarkOverlay()}
                {renderShareOverlay()}
                <ImageWrapper
                    src={(mediaItems !== undefined && mediaItems.length && mediaItems[currentDisplayedMedia].url) || ""}
                    alt=""
                    className={"feed-post-locker-image"}
                    role={mediaItems[currentDisplayedMedia]?.role || 0}
                    onClick={toggleModal}
                    poster={mediaItems[currentDisplayedMedia]?.role === 1 ? getThumbnail(properties, mediaItems[currentDisplayedMedia].id) : ""}
                    width={mediaItems[currentDisplayedMedia]?.width}
                    height={mediaItems[currentDisplayedMedia]?.height}
                    size={"Post"}
                    lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                />

                    <h3 className="position-absolute heading" onClick={toggleModal} style={{ cursor: "pointer" }}>
                        {title}
                    </h3>

                {
                    mediaItems.length > 1 ?
                        (
                            <>
                                <span className="count position-absolute d-flex justify-content-center align-items-center">
                                    {`${currentDisplayedMedia + 1}/${mediaItems.length}`}
                                </span>

                                <a href="/" className="left-arrow position-absolute d-flex justify-content-center align-items-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        prevPicture()
                                    }}>
                                    <RightArrowIconSmall />
                                </a>

                                <a href="/" className="right-arrow position-absolute d-flex justify-content-center align-items-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        nextPicture()
                                    }}>
                                    <RightArrowIconSmall />
                                </a>
                            </>

                        ) : null
                }

            </div>

            <div className="feed-detail px-1 pt-1 pb-0">


                {(products != null && products.length > 0) || (content != null || (products != null && products.length > 0)) ?
                    <div className={content == null ? "text-center pt-0 pb-2 mb-0" : "text-center py-2 mb-0"}>
                        {displayTextContent()}
                            <div className="mt-1 px-1" style={{ display: "table", tableLayout: "fixed", width: "100%", textAlign: "left" }}>
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                    <a href={redirectUrl} target="_target">Read more</a>
                                </div>
                            </div>

                        {
                            products && products.length > 0 ? (
                                <div {...productPageHandlers} className={`row mx-0 product-image-gallery justify-content-center px-0 position-relative mt-2`}>
                                    {renderProductPagination()}
                                    {mapProductImages()}
                                </div>
                            ) : null
                        }
                    </div> : null}
            </div>
        </div>
        // </div>
    )

}

export default connect(null, { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal })(BlogFeedPost);