import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { debounce } from "throttle-debounce"

import FormInputField from "components/FormInputField";
import MentionsInputTextArea from "components/MentionsInputWrappers/MentionsInputTextArea";
import ActionButton from "components/ActionButton";

import { postRequest, getRequest } from "sharedUtils/httpUtils";
import { Create } from "../../views/Post/Create";


/**
 * 
 * @param {{closeModal:Function}} props
 */
const CreateLocker = (props) => {
    const [formState, setFormState] = useState({ name: "", description: "", interests: [] });
    const [tagInput, setTagInput] = useState("");
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [finishedCreating, setFinishedCreating] = useState(false);
    const [lockerShareCode, setLockerShareCode] = useState("");
    const [isCreatingLocker, setIsCreatingLocker] = useState(false);
    const [lockerCreateMessage, setLockerCreateMessage] = useState({showMessage:false,messageText:""});

    const debounceFunc = React.useCallback(debounce(300, (query, callback) => fetchUsers(query, callback)), []);

    useEffect(() => {
        fetchTags();
    }, [])

    function addTag(sector) {
        let newTags = [...formState.interests];
        newTags.push(sector);
        let newFormState = { ...formState, interests: newTags };
        setTagInput("");
        setFormState(newFormState);
    }

    async function createLocker() {
        if (formState.description === undefined || formState.description.length === 0 || formState.name === undefined || formState.name.length === 0) {
            setLockerCreateMessage({ showMessage: true, messageText: "please add a description and name" });
            setTimeout(() => {
                setLockerCreateMessage({ showMessage: false, messageText: "" });
            }, 2500);
            return null
        }
        else {
            setIsCreatingLocker(true);
            let userSectorModels = formState.interests.map((sec) => {
                return {
                    sector: { id: sec.sectorId, name: sec.name }
                }
            });

            let payload = {
                content: formState.description,
                title: formState.name,
                sectors: userSectorModels,
                platformTypeId: 0
            };

            let locker = await postRequest(`/api/Locker/CreateLocker`, payload);
            setFormState({ name: "", description: "", interests: [] });

            setIsCreatingLocker(false);

            if (locker === undefined) {
                setLockerShareCode("");
                setFinishedCreating(false);
            }
            else {
                setLockerShareCode(locker.shareCode);
                setFinishedCreating(true);
                //props.closeModal();
                //history.push(`/Locker/NewLockerPage/${locker.shareCode}`);
            }
        }

    }

    function cancel() {
        setFormState({ name: "", description: "", interests: [] })
        props.closeModal();
    }

    async function fetchTags() {
        let tags = await getRequest(`/api/Sector/GetSectors`);
        tags = tags.sort((a, b) => { if (a.name < b.name) return -1; });
        setTagSuggestions(tags);
        return tags;
    }

    async function fetchUsers(query,callback) {
        if (!query || query.length < 1) {
            return;
        }
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;
        let response = await getRequest(url);
        let mappedResults;

        if (response === undefined) {
            mappedResults = []
        }
        else {
            mappedResults = response.map(user => ({ display: user.userName, id: user.id, name: user.name }))
        }
        callback(mappedResults)
    }

    async function handleInput(e) {
        let input = { [e.target.name]: e.target.value };
        setFormState({ ...formState, ...input });
    }
   
    async function handleInterestsInput(e) {
        setTagInput(e.target.value)
    }

    function mapTagSuggestionsAlternate() {
        if (tagSuggestions?.length > 0) {
            let filtered = tagSuggestions.filter(tag => tag.name.toLowerCase().includes(tagInput.toLowerCase()));

            return filtered.map((suggestion, i) => {
                let alreadySelected = formState.interests.findIndex((interest) => interest.sectorId === suggestion.sectorId);

                if (alreadySelected >= 0) {
                    return (
                        <button key={i} className="btn d-flex align-items-center active" onClick={() => removeTag(alreadySelected)}>
                            {suggestion.name}
                        </button>
                    )
                }
                else {
                    return (
                        <button className="btn d-flex align-items-center" onClick={() => addTag(suggestion)} key={i}>
                            {suggestion.name}
                        </button>
                    )
                }

            })
        }
    }

    function removeTag(location) {
        let newTags = [...formState.interests];
        newTags.splice(location, 1);
        let newFormState = { ...formState, interests: newTags }
        setTagInput("");
        setFormState(newFormState);
    }

    let createButtonDisabled = (formState.name.length === 0 || formState.description.length === 0) ? true : false;

    return (
        <div className="row mt-0 mx-0">
            {
                finishedCreating === true && lockerShareCode.length > 0 ? (
                    <Redirect to={{
                        pathname: `/Locker/NewLockerPage/${lockerShareCode}`,
                        state: {
                            justCreated: true
                        }
                    }} />
                ) : <span></span>
            }
            <div className="col-md-12">

                <h5 className="">Create Locker</h5>

                <div className="form">

                    <FormInputField
                        title="Name"
                        name="name"
                        inputType="text"
                        onInputChange={handleInput}
                        value={formState.name}
                        inputStyle={{ backgroundColor: "#e9ecef" }}
                        placeholder="E.g., Backpacking - Winter 2021 Pack List"
                        maxLength={256}
                    />
                    <p className="position-relative" style={{ bottom: "10px", textAlign: "right", fontSize: "12px", color: "#717171" }}>{256 - formState.name?.length} characters remaining</p>
                    <label className="control-label" style={{ fontFamily: "Archivo" }}>
                        <strong>Description</strong>
                    </label>
                    <MentionsInputTextArea
                        onChange={(e) => setFormState({ ...formState, description: e.target.value })}
                        style={{ backgroundColor: "#e9ecef",height:"auto" }}
                        value={formState.description}
                        data={debounceFunc}
                        placeholder="Tell us about your locker and the purpose of its contents"
                        className="caption-textarea"
                    />

                    <FormInputField
                        title="Interests"
                        name="interests"
                        inputType="search"
                        onInputChange={handleInterestsInput}
                        value={tagInput}
                        inputStyle={{ backgroundColor: "#e9ecef" }}
                        placeholder="Search for interests..."
                        id="gsearch"
                    />

                    <div className="search-block">
                        <div className="tag-results-sm d-flex justify-content-center flex-wrap py-1">
                            {mapTagSuggestionsAlternate()}
                        </div>
                    </div>

                    <div className="form-group button-block text-md-right mb-0">
                        <button className="btn btn-secondary mt-3" onClick={cancel}>
                            Cancel
                        </button>

                        <ActionButton
                            className="btn btn-primary ml-3 mt-3"
                            onClick={createLocker}
                            isDisabled={createButtonDisabled}
                            isLoading={isCreatingLocker}
                            message={lockerCreateMessage}
                        >
                            Next
                        </ActionButton>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default CreateLocker;