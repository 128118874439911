import React from "react";
import { Link } from "react-router-dom";
import ResetIcon from "components/icons/ResetIcon"
import AccountBanner from "components/AccountBanner";
import PenIcon from "components/icons/PenIcon";
import ActionButton from "components/ActionButton";
import Modal from 'react-modal';

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";

import { DateTime } from "luxon";
import { createReturn } from "typescript";

class AffiliateNetworks extends React.Component {

    state = {
        affiliateNetworks: [],
        isEditing: false,
        newApmName: "",
        newApmUrl: "",
        commissionRuns: [],
        commissionStatusByApm: [],
        show: false,
        showCommissionModal: false,
    }

    async componentDidMount() {
        let networks = await this.fetchAffiliateNetworks();
        let commissionRuns = await this.fetchCommissionRuns();
        const affiliateNetworks = await this.calculateMerchantSynchronizationDate(networks);
        await this.calculateCommissionRunStatus(commissionRuns, networks);

        this.setState({ affiliateNetworks, commissionRuns });
    }


    addNewApm = async () => {
        let networks = [...this.state.affiliateNetworks];

        let newApm = {
            name: this.state.newApmName,
            apiUrl: this.state.newApmUrl
        }

        networks.push(newApm);

        this.setState({ affiliateNetworks: networks, newApmName: "", newApmUrl: "" });

        let url = "api/AffiliateProgramManager/AddAffiliateProgramManager";

        let created = await postRequest(url, newApm);
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/AffiliateNetworks', text: 'Affiliate Networks' }
    ]

    displayHeaders = () => {
        let headers = ["Name", "Api Url", "Merchant Synch Status", "Commission Status", "Edit"];
        return headers.map((header, i) => {
                if (i === 2 || i === 3) {
                return (
                    <th key={i} style={{ width: "110px" }}>
                        <strong>{header}</strong>
                    </th>
                )
            }
            return (
                <th key={i}>
                    <strong>{header}</strong>
                </th>
            )

        })
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    onRefreshYes = (e) => {
        e.preventDefault();

        this.updateMerchantSynch();

        this.setState({ show: false })
    };

    showCommissionModal = (e) => {
        e.preventDefault();
        this.setState({ showCommissionModal: !this.state.show });
    };

    hideCommissionModal = () => {
        this.setState({ showCommissionModal: false });
    };

    onCommissionRefreshYes = (e) => {
        e.preventDefault();

        this.updateCommissionByAffiliate();

        this.setState({ showCommissionModal: false })
    };

    calculateCommissionRunStatus = (commissionRuns, affiliateNetworks) => {
        commissionRuns.map(cr => {
            const affiliateName = affiliateNetworks.filter(affiliate => cr.affiliateProgramManagerId === affiliate.id)[0].name.toLowerCase();
            const isSaS = affiliateName === "shareasale";

            const dateNow = new Date();
            const date = new Date(dateNow);
            const utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            const currentUtc = new Date(utc);
            const createdDate = new Date(cr.createdDate);
            const millisecondDate = new Date(createdDate);
            const fiveHoursInMilliseconds = 60 * 60 * 1000 * 5;
            const commissionSynchDateLocal = millisecondDate - fiveHoursInMilliseconds;

            const commissionRunHours = Math.floor((currentUtc - createdDate) / 1000 / 60 / 60);
            let commissionStatus = "";

            if (isSaS) {
                if (commissionRunHours <= 24) {
                    commissionStatus = "green";
                } else if (commissionRunHours > 24 && commissionRunHours <= 48) {
                    commissionStatus = "yellow";
                } else {
                    commissionStatus = "red";
                }
            } else {
                if (commissionRunHours <= 12) {
                    commissionStatus = "green";
                } else if (commissionRunHours > 12 && commissionRunHours <= 24) {
                    commissionStatus = "yellow";
                } else {
                    commissionStatus = "red";
                }    
            }

            this.setState({ commissionStatusByApm: [...this.state.commissionStatusByApm, { apmId: cr.affiliateProgramManagerId, commissionStatus, commissionCreatedDate: commissionSynchDateLocal }] })

        })
    }

    calculateMerchantSynchronizationDate = (networks) => {
        let updatedNetworks = [...networks];
        updatedNetworks.map(network => {
            if (network.merchantSynchDate != undefined && network.merchantSynchDate != null) {
                const dateNow = new Date();
                const date = new Date(dateNow);
                
                const merchantSynchDate = new Date(network.merchantSynchDate);
                const millisecondDate = new Date(merchantSynchDate);
                const fiveHoursInMilliseconds = 60 * 60 * 1000 * 5;
                const merchantSynchDateLocal = millisecondDate - fiveHoursInMilliseconds;

                const merchantSynchDateDifferenceInHours = Math.floor((date - merchantSynchDateLocal) / 1000 / 60 / 60);
                let msdStatus = "";

                if (merchantSynchDateDifferenceInHours <= 24) {
                    msdStatus = "green";
                } else if (merchantSynchDateDifferenceInHours > 24 && merchantSynchDateDifferenceInHours <= 48) {
                    msdStatus = "yellow";
                } else {
                    msdStatus = "red";
                }

                updatedNetworks = updatedNetworks.map(nw => {
                    if (nw.id === network.id) {
                        return { ...nw, merchantSynchStatus: msdStatus, merchantSynchDate: merchantSynchDateLocal  };
                    } else {
                        return {...nw}
                    }
                })
            }
        })
        return updatedNetworks;
    }

    displayRows = () => {
        return this.state.affiliateNetworks?.map((network, i) => {
            const statusObj = this.state.commissionStatusByApm.find(status => status.apmId === network?.id);
            const status = statusObj == undefined ? "" : statusObj.commissionStatus;
            const commissionSynchDate = statusObj == undefined ? null : statusObj.commissionCreatedDate;

            return (
                <tr key={i}>
                    <td>
                        {network?.name}
                    </td>

                    <td className="text-left">
                        {network?.apiUrl}
                    </td>

                    <td>
                        <div className={`commission-status-${network?.merchantSynchStatus}`}>
                            <div onClick={(e) => { this.setState({ apmId: network?.id }); this.showModal(e); }}>
                                <ResetIcon height="12" width="12" pathStroke="#b3b3b3" style={{ position: "relative", left: "50%", transform: "translate(-50%, 1px)" }} />
                            </div>
                        </div>
                        <p style={{ fontFamily: "'Archivo', sans-serif", fontSize: "9px", marginBottom: 0 }}>{convertToNumericMonthAndDayAndYearAndTime(new Date(network.merchantSynchDate), false)}</p>
                    </td>

                    <td>
                        <div className={`commission-status-${status}`}>
                            <div onClick={(e) => { this.setState({ apmId: network?.id }); this.showCommissionModal(e); }}>
                                <ResetIcon height="12" width="12" pathStroke="#b3b3b3" style={{ position: "relative", left: "50%", transform: "translate(-50%, 1px)" }} />
                            </div>
                        </div>
                        <p style={{ fontFamily: "'Archivo', sans-serif", fontSize: "9px", marginBottom: 0 }}>{commissionSynchDate ? convertToNumericMonthAndDayAndYearAndTime(new Date(commissionSynchDate), false) : ""}</p>
                    </td>

                    <td>
                        <Link to={ `/Admin/AffiliateNetworks/${network.id}`}>
                            <PenIcon />
                        </Link>
                    </td>
                </tr>
            )
        })
    }


    fetchAffiliateNetworks = async () => {
        let url = "api/AffiliateProgramManager/GetAffiliateProgramManagers";
        let networks = await getRequest(url);
        return networks;
    }

    updateMerchantSynch = async () => {
        let url = "api/Merchant/GetMerchantsFromApms?apmId=" + this.state.apmId;
        await getRequest(url);
    }

    updateCommissionByAffiliate = async () => {
        let url = "api/Merchant/GetCommissionsBase?apmId=" + this.state.apmId;
        await getRequest(url);
    }

    fetchCommissionRuns = async () => {
        let url = "api/AffiliateProgramManager/GetLastCompletedCommissionRunAll";
        let commissionRuns = await getRequest(url);
        return commissionRuns;
    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="mx-0 my-0">
                            <h1 style={{ textAlign: "center", display: "block", fontSize: '1.75rem' }}>
                                Are you sure that you want to Update this Affiliate Network's Merchants?
                            </h1>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                This will synchronize approved merchants from this Affiliate Networks
                            </p>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                            <button type="button" className="btn btn-primary mr-2" onClick={(e) => this.onRefreshYes(e, this.state.apmId)}>Yes</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={() => this.setState({ show: false })}>No</button>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showCommissionModal}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="mx-0 my-0">
                            <h1 style={{ textAlign: "center", display: "block", fontSize: '1.75rem' }}>
                                Are you sure that you want to Update this Affiliate Network's Commissions?
                            </h1>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                This will synchronize commissions from this Affiliate Networks
                            </p>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                            <button type="button" className="btn btn-primary mr-2" onClick={(e) => this.onCommissionRefreshYes(e, this.state.apmId)}>Yes</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={() => this.setState({ showCommissionModal: false })}>No</button>
                        </div>
                    </div>
                </Modal>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Affiliate Networks</h1>
                        <p></p>
                    </AccountBanner>
                </section>

                <section className="gray-bg full-height">
                    <div className="container pt-0">

                        <div className="row mx-0 my-0">
                            <div className="table-responsive product-table mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {this.displayHeaders()}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.displayRows()}
                                    </tbody>
                                </table>

                                
                            </div>

                            <div className="input-group mt-2">

                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    placeholder="Name"
                                    onChange={e => this.setState({ newApmName: e.target.value })}
                                />

                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    placeholder="Api Url"
                                    onChange={e => this.setState({ newApmUrl: e.target.value })}
                                />

                                

                                <ActionButton
                                    isDisabled={this.state.newApmName.length === 0 || this.state.newApmUrl.length < 5}
                                    className="btn btn-primary mx-2"
                                    onClick={this.addNewApm}
                                >
                                    Add +
                                </ActionButton>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}



export default AffiliateNetworks